

.alert{

	&.alert-status{@extend .alert-success !optional}
	&.alert-error{@extend .alert-danger !optional}

	.messages__icon{
		@extend .pull-left !optional
		margin-right:20px;
	}
	
	.messages__list{
		@extend .pull-left !optional
	}
	
	a{@extend .alert-link !optional}

	
}