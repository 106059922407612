


ul.tabs{
	@extend .nav;
	@extend .nav-pills;
	
	a{
		@extend .nav-link;
	}
}

	.tabs__tab{
		@extend .nav-item;
	}