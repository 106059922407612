//

.contact-info{
	@extend .row;
	@extend .mb-3;
	@extend .pb-3;
	// @include media-breakpoint-down(xs) { border-bottom: 1px solid theme-color("secondary"); }
	
	.photo-block{
		@extend .col-md-4;
		@extend .col-lg;
		@extend .col-xl-4;
	}
	.address-block{
		@extend .col-md-8;
		@extend .col-lg;
		@extend .col-xl-8;
	}
	
	img{
		@extend .rounded-circle;
		@include media-breakpoint-down(sm) { display:none; }
	}
	
}

.address-block{
	> div{margin-bottom:0.75em;}
	.icon{
		float:left;
		width: $grid-gutter-width;
	}
	.text{float:left;}
}


aside{
	.contact-info{
		.photo-block{display:none;}
		.address-block{
			@extend .col;
			.title{display:none;}
		}
	}
}