


//normal
$logo-height:2rem;
$navbar-link-height: 0.9rem;
$header-height: $navbar-padding-y * 2 +  $navbar-link-height + $logo-height ;


//big
$logo-height-big:2.8125rem;
$navbar-padding-y-big: $spacer * 1;
$header-height-big: $navbar-padding-y-big * 2 +  $navbar-link-height + $logo-height-big ;



body{padding-top: $header-height-big;}
body.header-fixed-shrink{padding-top: $header-height;}


@include media-breakpoint-up(md) {
	.navbar-collapse {
		flex-basis:auto;
	}
}



body.admin-menu{padding-top: $header-height-big + 2rem !important;}
body.header-fixed-shrink.admin-menu{padding-top: $header-height + 2rem !important;}

body.admin-menu header.fixed-top{
top:30px !important;
}



header.navbar{
	.menu.main-menu{
		.nav-link{
			padding: $nav-link-padding-y $nav-link-padding-x !important;
			font-size: $navbar-link-height;
		}
	} 
	padding: $navbar-padding-y-big $navbar-padding-x;
	transition:$transition-base;
	
	.navbar-brand{
		img{
			width: auto;
			height: $logo-height-big;
		}
	}
	
	
}



header.navbar.header-fixed-shrink{
	.menu.main-menu{
		.nav-link{
			padding: $nav-link-padding-y $nav-link-padding-x !important;
			font-size: $navbar-link-height;
		}
	} 
	padding: $navbar-padding-y $navbar-padding-x;
	transition:$transition-base;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
	
	.navbar-brand{

		img{
			width: auto;
			height: $logo-height;
		}
	}
	
}


//nav link-padding in viewports

@include media-breakpoint-down(md) { 
	header.navbar .menu.main-menu{
		.nav-link{
			padding-left:$nav-link-padding-x / 2 !important;
			padding-right:$nav-link-padding-x /2 !important;
		}
	} 
}

//ausklappen der untermenus on hover
.nav-item-hover-dropdown:hover{
	.dropdown-menu{
		display:block;
		margin-top:0;
	}

}




header.navbar{

	border-bottom: 1px solid $table-border-color;

	i[class*="pe-7s"]{
		font-size:15px;
	}
	
	

	.menu.main-menu{
		// @extend .mr-auto;
		.nav-link{
			// font-family: 'Raleway', sans-serif;
			font-style: normal;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 1px;
	
	
		}
	} 
	
		
		font-size: 1rem;
		.btn-group{
		font-size: 1rem;
		}

	.navbar-brand{
			padding:0;
		img{
			padding-top:0;
			padding-bottom:0;
			transition:$transition-base;
		}
	}


	
	
	#block-custom-header-toolbar-icons{
		a.btn{
			@extend .btn-outline-success;
			padding-top: 0.3rem;
			padding-bottom: 0.2rem;
		}
		
	}
}




