//


.view.view-articles.view-display-id-page{

	.views-row {
		@extend .pt-5;
		@extend .pb-5;
		
		&:first-child{			@extend .pt-0;		}
		&:last-child{
			@extend .pb-0;	
			@extend .mb-5;	
			border:0 none;

		}
		
		border-bottom: 1px solid theme_color("primary");

		.field-name-title{
			
		}
		
		.field-name-post-date{
			@extend .p-2;
			@extend .mb-2;
			background-color: $gray-100;;
			border-color: $table-border-color;
			border-style:solid;
			border-width: 1px 0 1px 0;
			font-size:$font-size-sm;
			// @extend .text-muted;
		}
	}


}








.view-id-hunde{

	.view-content{
		@extend .card-deck;
		@extend .justify-content-around;
		.card{
			flex: 0 1 35%;
			.card-header{
				@extend strong;
			}
			@extend .mb-4;
			@extend .mb-sm-0;
		}
	}


}

.entity-paragraphs-item {
	.view-id-hunde{
		.view-footer{display:none;}
	}
}



.view-id-articles.view-display-id-block_1{
	.view-content{
		@extend .card-deck;
		@extend .justify-content-around;
		.card{
			
			.card-header{
				@extend strong;
			}
			@extend .mb-4;
			// @extend .mb-sm-4;
			
			.post-date{@extend .float-left;}
			.comment-count{@extend .float-right;}
		
			@include media-breakpoint-only(sm) { flex: 0 1 40%; }
			@include media-breakpoint-only(md) {    
				flex: 0 1 21%;
				margin: 0 5px; 
			}
		
		}
	}
	
	.more-link a{
		@extend .btn-block;
		@extend .btn;
		@extend .btn-outline-primary;
		// @extend .mt-3;
	}
}


.view-id-articles.view-display-id-block_1.view-count-1 .view-content .card{
    flex: 0 0 30%;
	@include media-breakpoint-only(sm) { flex: 0 0 50%; }

}

.view-id-articles.view-display-id-block_1.view-count-2 .view-content .card{
    flex: 0 0 30%;
	@include media-breakpoint-only(md) { flex: 0 0 35%; }
	@include media-breakpoint-only(sm) { flex: 0 0 40%; }
}

.view-id-articles.view-display-id-block_1.view-count-3 .view-content .card{
    flex: 0 0 30%;
}








@import url('https://fonts.googleapis.com/css?family=Handlee');

.view-id-messer.view-display-id-block{

	.row{
		@extend .justify-content-center;
	}
	
	
	.messer-card-wrapper{
		@extend .col;
		@extend .col-sm-11;
		@extend .col-md-10;
		@extend .col-lg-8;
		@extend .col-xl-6;

	}
	.card.messer-card{
		color:#000;
		@extend .border-0;
		h3.card-title{
			font-size: 2rem;
			font-family: 'Handlee', cursive;
			margin-bottom:0;
			text-align:left;
			@include media-breakpoint-down(sm) { font-size: 1.5rem; }
			@include media-breakpoint-down(xs) { text-align:center; margin-top: $spacer; }
		}
		
		.card-body > div{
			@extend .row;
			@extend .align-items-center;
			line-height: 2rem;
		}
		.messer-headline-wrapper{
			@extend .col;
		}
		
		
		.messer-logo-wrapper{
			width:75%;
			max-width:120px;
			min-width:100px;
			// @extend .my-2;
			@extend .col-4;
		}
		
		.btn.btn-success{
			@extend .mt-2;
		}
		
		.messer-logo{

		}
	}
}


.view-id-messer.view-display-id-block_1{

	.view-content{@extend .card-deck;}

	.card{
		flex-basis: 35%;
	}
	
		h3.card-title{
			font-size: 2rem;
			font-family: 'Handlee', cursive;
			margin-bottom:0;
			text-align:left;
			@include media-breakpoint-down(sm) { font-size: 1.5rem; }
			@include media-breakpoint-down(xs) { text-align:center; margin-top: $spacer; }
		}



















}







.pager {
  display: flex;
  // 1-2: Disable browser default list styles
  padding-left: 0; // 1
  list-style: none; // 2
  @include border-radius();
  @extend .justify-content-center;
  @extend .mt-3;
  @extend .mb-5;
}

.pager li {


  &:first-child {
    a {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    a {
      @include border-right-radius($border-radius);
    }
  }

  &.pager-current,
  &.active a {
    z-index: 2 !important;
    color: $pagination-active-color !important;
    background-color: $pagination-active-bg !important;
    border-color: $pagination-active-border-color !important;
  }

  &.disabled a {
    color: $pagination-disabled-color;
    pointer-events: none;
    // cursor: $cursor-disabled; // While `pointer-events: none` removes the cursor in modern browsers, we provide a disabled cursor as a fallback.
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


.pager li.pager-current{
  &:first-child {
      margin-left: 0;
      @include border-left-radius($border-radius);
  }
  &:last-child {
      @include border-right-radius($border-radius);
  }
}






.pager li.pager-current,
.pager li a {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -1px;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  @include hover-focus {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }
}


