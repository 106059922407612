@import 'init/clearfix';



.hide,
.element-invisible{display:none;}
.visually-hidden{display:none;}


img {
    border: 0;
    max-width: 100%;
}

table{
	width:100%;
}



