


//****************
//1 spaltig
//****************


main.layout-3col__full{
@extend .col-12;
}

//****************
//3 spaltig
//****************

aside.layout-3col__second-left-sidebar,
aside.layout-3col__first-left-sidebar{
	@extend .col-lg-3;
}

//main
main.layout-3col{
	@extend .order-md-2;
	@extend .col;
}


//first
aside.layout-3col__first-sidebar{
	@extend .order-md-1;
	@extend .col-md-2;
}

//second
aside.layout-3col__second-sidebar{
	@extend .order-md-12;
}



//****************
//2 spaltig mit second sidebar
//****************


//main
main.layout-2col__second-sidebar{
	@extend .col;
}
// second
aside.layout-2col__second-sidebar{
	@extend .col-md-3;
	// @extend .col-lg-2;
}




//****************
//2 spaltig mit first sidebar
//****************


//main
main.layout-2col__first-sidebar{
	@extend .col;
	@extend .order-md-2;
}


// second
aside.layout-2col__first-sidebar{
	@extend .order-md-1;
	@extend .col-md-3;
	@extend .col-lg-2;
}


















