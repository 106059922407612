
body.front{
	.frontpage-bgvideo-hero{
		h1{margin-top: 200px;}
	}
}


.frontpage-parallax-hero{
	@extend .text-center;
	@extend .text-white;
	@extend .py-5;
	
	img{
		@extend .mb-3;
	}
}



//frontpage
.frontpage-panel-section{
	@extend .mb-7;
	
	.panel-pane{
		@extend .clearfix;
	}
	
	.panel-pane:not(:first-child):not(.info-panel-frontpage){
		@extend .mt-7;
	}
	
	.panel-pane.info-panel-frontpage{
		@extend .mt-4;
		@extend .row; 
		@extend .justify-content-center;
		.alert{
			margin:0;
			@extend .col-md-10;
			@extend .col-lg-8;
		}
	}
	
	
	//über uns margin bottom bei kleinen viewoports
	.ds-2col.node{
		.col-md.group-left{
			@extend .mb-4;
			@extend .mb-lg-0;
		}
	}
}


#admin-menu{z-index:9999;}


.map-responsive{
    overflow:hidden;
    // padding-bottom:56.25%;
    padding-bottom:25%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
	
	.gm-style .place-card-large {
		display:none;
	}
}


.ds-2col-stacked.node-webform{
	.group-header{
		@extend .mb-md-5;
		@extend .mb-sm-3;
	}
	
	.group-left{@extend .mr-xl-3;}
	.group-right{@extend .ml-xl-3;}
}


.dl-horizontal{
	@extend .row;
	dt{@extend .col-md-3;}
	dd{@extend .col-md-9;}



}

.flex-no-grow{
	flex-grow:0 !important;
}

.carousel-item{
	.image-title{
		text-shadow: 0px 0px 3px #000;
	}
}


p:last-child{
	@extend .mb-0;
}