article.node{
	@extend .mt-3;
	@extend .mb-6;
}


.field-name-field-images .field-item {@extend .mb-4;}
.field-type-paragraphs .field-item {@extend .mb-4;}


.videopopup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
}

.responsive-video iframe {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

a.videopopup{
	display: block;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
	
	&:hover{
	background-color: rgba(0, 0, 0, 0.05);
	}
}

.modal-backdrop.in {
    opacity: 0.8;
}

.videomodal{



	.modal-content{
		background-color: transparent;
		border-width: 0px;
		box-shadow: 0 0 0;
	}
	
	.close {
		font-size: 40.5px;
		color: #fff;
		text-shadow: 0 0 0;
		opacity: 1;
	}
}