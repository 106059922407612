//

@mixin title-wrapper{
		display: block;
		margin: 10px 0 25px 0;
		border-bottom: 1px dotted #e4e9f0;;
		.title,
		.wrapper__title,
		.block__title{
			margin: 0 0 -2px 0;
			color: #666;
			padding-bottom: 5px;
			display: inline-block;
			border-bottom: 2px solid theme-color("primary");
		}
}


@mixin fancy-headline{
	color: #666;
	padding-bottom: 5px;
	margin-bottom: 1rem;
	display: inline-block;
	border-bottom: 2px solid theme-color("primary");
}


.title-wrapper,
.block__title-wrapper{
	@include title-wrapper;
}



.fancy-headline,
body.front .pane-title.pane__title{
	@include fancy-headline;
}


.page-title-wrapper{

	h1{
		color: #666;
		font-size: 22px;
		margin-top: 18px;
		margin-bottom: -1px;
		border-bottom: 2px solid theme-color("primary");
		display: inline-block;
		padding-bottom: 15px;
	}
	border-bottom: 1px solid #ddd;

}

.main-tabs-wrapper{
	@extend .mb-3;
}