
.ajax-progress .throbber {
    background: transparent url(../images/throbber.svg) no-repeat 0px center;
    // background: transparent url(../../../../../misc/throbber-active.gif) no-repeat 0px center;
    float: left;
    height: 15px;
    margin: 2px;
    width: 15px;
	padding: 1px 10px 2px 3px !important;
}

#edit-actions.form-actions{
	input[type="submit"]#edit-submit{@extend .btn-primary;}
	input[type="submit"]#edit-delete{@extend .btn-outline-danger;}
	input[type="submit"]{
		@extend .mr-3;
	}
}



.form-type-managed-file.has-danger{

	.form-element-wrapper{@extend .text-danger;}
	.form-submit{@extend .btn-outline-danger;}

}

  select,
  textarea,
  input[type="text"] {
    // font-size: 16px !important;
  }

  
  input[type="email"] {
	@extend .form-control;
  }
  
  
  .form-required{
	@extend .text-danger;
  }
  
  .form-element-label{
	font-weight:bold;
  }
  
  
  fieldset.form-wrapper{
	legend{
		font-size: $font-size-base;
		@extend .form-element-label;
	}
	
	.fieldset-wrapper{
		@extend .bg-light;
		@extend .p-3;
		border: 1px solid $table-border-color;
	}
	
	@extend .form-group;
  }
  
  
  .container-inline-date .date-padding {
    float: none;
}

.container-inline-date{
	// @extend .form-group;
	// @extend .clearfix;
}
  
  
  
form.inline-form{
	.form-group {@extend .row;}
	.form-element-label 	{@extend .col-sm-3;}
	.form-element-wrapper 	{@extend .col-sm-9;}

	.form-group {
		@extend .mb-4;
	}

	.container-inline-date .form-group .form-group .form-element-wrapper,
	.container-inline-date .form-group .form-group{
		margin:0 !important;
		padding:0 !important;
	}
}  




.form-buttons{
	@extend .my-5;
	@extend .p-3;
	@extend .bg-light;
}


table.tabledrag-processed{

	input[type="submit"].remove{
		@extend .btn-outline-danger;
		@extend .btn-sm;
	}
	
	a.button.edit{
		@extend .btn-primary;
		@extend .btn;
		@extend .btn-sm;
		@extend .mr-1;
	}


}


.draggable a.tabledrag-handle {
    cursor: move;
    float: left;
    height: 1.7em;
    margin-left: -1em;
    overflow: hidden;
    text-decoration: none;
	box-sizing: content-box;
}


a.tabledrag-handle .handle {
    background: url(../images/draggable.png) no-repeat 6px 9px;
    height: 13px;
    margin: -0.4em 0.5em;
    padding: 0.42em 0.5em;
    width: 13px;
}

.warning.tabledrag-changed{
	@extend .text-danger;
	font-weight: bold;
}

tr.drag{
	background-color: lighten($yellow, 25%);
}
tr.drag-previous{
	background-color: lighten($yellow, 40%);
}




.form-item.form-group.form-type-media{
	@extend .p-3;
	@extend .bg-white;
	a.button.browse{
		@extend .btn;
		@extend .btn-success;
	}
} 

//webforms
.form-item.webform-component {
	@extend .mb-2;
	.form-element-label{@extend .mb-0;}


}


