//background video

/* Settings - feel free to play with these */
$overlay_opacity: 0.3; // 0-1
// http://html5backgroundvideos.com/pattern-overlays/
// $overlay_pattern: 'https://d3k5xyayaartr5.cloudfront.net/_assets/pattern-overlays/patterns/black-medium-checks.png'; // Grab the url of a png from here - http://html5backgroundvideos.com/pattern-overlays/
// $overlay_pattern: 'https://d3k5xyayaartr5.cloudfront.net/_assets/pattern-overlays/patterns/black-criss-cross.png'; // Grab the url of a png from here - http://html5backgroundvideos.com/pattern-overlays/

$videobg-shadow:5px 5px 5px #222;

// @import url(https://fonts.googleapis.com/css?family=Roboto:400,700);




/* Video overlay and content */
.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none; /* Allows right click menu on the video */
	// background: url($overlay_pattern) left top repeat;
	opacity: $overlay_opacity;
}
.video-hero--content {
	position: relative;
    text-align: center;
    color: #FFF;
    @extend .my-4;
    
	h1 {
		font-size: 3rem;
		font-weight: 600;
		 margin-top: $grid-gutter-width;
	}
	
	h1,
	h2{
		text-shadow: $videobg-shadow;
	}

	p {
		font-size: 16px;
		// text-shadow: $videobg-shadow;
	}

	.frontpage-bgvideo-hero{
		img{
			-webkit-filter: drop-shadow($videobg-shadow);
			filter: drop-shadow($videobg-shadow);
		}
	}
	
}


.jquery-background-video-pauseplay{
	left: auto  !important;
	right: 15px !important;
	top: auto !important;
	bottom: 15px !important;

}


.parallax-overlay{
	@extend .py-5;
}


.blacksmith-video{
	.video-overlay {
		background-color:black;
		opacity: 0.5;
	}
}