


	
footer{
    // width: auto;
    height: $footer-height;
	width:100%;
    position:absolute;
    bottom:0;
    left:0;
	
    // overflow: hidden;
    @extend .bg-dark;
    @extend .text-white;
	a{
		@extend .text-white;
	}
	@extend .py-3;
	@extend .mt-5;
}
.footer {

	.block{
		@extend .col-sm;
	
	}

	.block-masquerade{
		.container-inline{
			@extend .clearfix;
		}

		.container-inline,
		.container-inline + .form-item{
			display:inline-block;
			margin: 0 10px 0 0 !important;
		}
		
		
		
		.form-item.form-type-textfield {
			width:200px;
			margin:5px !important;

			input{
				@extend .form-control-sm;
			}
		}


		.form-item.form-type-textfield,
		input#edit-submit{
			display:inline-block;
		}	

		#quick_switch_links{
			display:initial;
			li{
				display: inline-block;
				margin-right: 10px;
			}
		}

	}
	
}