//font
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600';




$enable-rounded:            false;
$enable-gradients:         	true;
$enable-shadows:         	true;


//variables
$font-family-sans-serif:  'Open Sans', sans-serif;
$footer-height:  5rem;


$font-size-base: 0.8rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1rem;
$font-size-sm:   .7rem;

$h1-font-size: 2rem;
$h2-font-size: 1.4rem;
$h3-font-size: 1.3rem;
$h4-font-size: 1.1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4),
  7: ($spacer * 5)
);




//colors
// color: #bfa97d;

$navbar-light-color:                #3f3f3f;
$navbar-light-hover-color:          #bfa97d;
$navbar-light-active-color:         #bfa97d;


$navbar-dark-color:                 #ffffff;
$navbar-dark-hover-color:           $navbar-light-hover-color;
$navbar-dark-active-color:          $navbar-light-active-color;



//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

// $brown:   #bfa97d !default;
$brown:   #846d3f !default;
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
	brown: $brown,
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary:  $brown,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);


$carousel-control-opacity: 1;

.carousel-control-prev{
	background-image: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
}

.carousel-control-next{
background-image:linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
}



$nav-link-padding-y:            .5rem;
$nav-link-padding-x:            1.5rem;


@import 'init';

@import 'bootstrap/scss/bootstrap';
$border-color: $table-border-color;

@import 'custom/layout';
@import 'custom/page';
@import 'custom/status_messages';
@import 'custom/tabs';
@import 'custom/forms';
@import 'custom/node';
@import 'custom/views';
@import 'custom/header';
@import 'custom/footer';
@import 'custom/modals';
@import 'custom/masquerade';
@import 'custom/kontakt';
@import 'custom/stuff';
@import 'custom/bg_video_block';






//lists
li,
ul {@extend .list-unstyled;}


//tables
table{
	@extend .table;
	th{
		border-top-width:0px;
	}
}


#main-container{
	@extend .mt-3;
	padding-bottom: $footer-height;
}

//fuer den footer

html,
body{height: 100%;}
.layout-center{
	min-height: 100%;
	position: relative;
}


img{
	@extend .img-fluid;
}


.messages{
	@extend .alert;
}

.messages.warning{
	@extend .alert-warning;
}

////////////////////////
/*
IE 11 hack:
https://github.com/twbs/bootstrap/pull/22288
https://github.com/twbs/bootstrap/issues/21885
*/

// Fix for IE11 and 10 (Edge works well):
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
.card{display:block !important;}
}

// .card{display:block !important;}